<script setup lang="ts">
import CardEvents from '@/components/CardEvents.vue'
import EventsService from '../services/events.service.js'
import { ref } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

const categ = ref('Reset')

const eventsPreview = ref()
const events = ref()
EventsService.getEvents().then(
  (response: { data: null; }) => {
    eventsPreview.value = response.data
    events.value = eventsPreview.value
  },
  (error: any) => {
    events.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

function getEventFilter (category: string) {
  categ.value = category

  if (category === 'Reset') {
    events.value = eventsPreview.value
  }
  if (category === 'Eveil') {
    events.value = eventsPreview.value.filter((item: { Eveil: string; Toutes: string; }) => item.Eveil === 'Oui' || item.Toutes === 'Oui')
  }
  if (category === 'Poussins') {
    events.value = eventsPreview.value.filter((item: { Poussins: string; Toutes: string; }) => item.Poussins === 'Oui' || item.Toutes === 'Oui')
  }
  if (category === 'Benjamins') {
    events.value = eventsPreview.value.filter((item: { Benjamins: string; Toutes: string; }) => item.Benjamins === 'Oui' || item.Toutes === 'Oui')
  }
  if (category === 'Minimes') {
    events.value = eventsPreview.value.filter((item: { Minimes: string; Toutes: string; }) => item.Minimes === 'Oui' || item.Toutes === 'Oui')
  }
  if (category === 'Cadets') {
    events.value = eventsPreview.value.filter((item: { Cadets: string; Toutes: string; }) => item.Cadets === 'Oui' || item.Toutes === 'Oui')
  }
  if (category === 'Fond') {
    events.value = eventsPreview.value.filter((item: { Fond: string; Toutes: string; }) => item.Fond === 'Oui' || item.Toutes === 'Oui')
  }
  if (category === 'Running') {
    events.value = eventsPreview.value.filter((item: { Running: string; Toutes: string; }) => item.Running === 'Oui' || item.Toutes === 'Oui')
  }
  if (category === 'Autres') {
    events.value = eventsPreview.value.filter((item: { Autres: string; }) => item.Autres === 'Oui')
  }
}

</script>
<template>
    <div class="text_titre">
        <h1>Les événements</h1>
    </div>
    <section class="ListCategories">
        <div class="ItemCateg Tous" @click="getEventFilter('Reset')" :class="categ == 'Reset' ? 'ItemCategActive' : ''">
            <h3>Tous les événements</h3>
        </div>
        <div class="ItemCateg eveil" @click="getEventFilter('Eveil')" :class="categ == 'Eveil' ? 'ItemCategActive' : ''">
            <h3>Eveil Athlé</h3>
        </div>
        <div class="ItemCateg poussins" @click="getEventFilter('Poussins')" :class="categ == 'Poussins' ? 'ItemCategActive' : ''">
            <h3>Poussins</h3>
        </div>
        <div class="ItemCateg benjamins" @click="getEventFilter('Benjamins')" :class="categ == 'Benjamins' ? 'ItemCategActive' : ''">
            <h3>Benjamins</h3>
        </div>
        <div class="ItemCateg minimes" @click="getEventFilter('Minimes')" :class="categ == 'Minimes' ? 'ItemCategActive' : ''">
            <h3>Minimes</h3>
        </div>
        <div class="ItemCateg cadets" @click="getEventFilter('Cadets')" :class="categ == 'Cadets' ? 'ItemCategActive' : ''">
            <h3>Sprinteur</h3>
        </div>
        <div class="ItemCateg fond" @click="getEventFilter('Fond')" :class="categ == 'Fond' ? 'ItemCategActive' : ''">
            <h3>Demi-Fond</h3>
        </div>
        <div class="ItemCateg running" @click="getEventFilter('Running')" :class="categ == 'Running' ? 'ItemCategActive' : ''">
            <h3>Running</h3>
        </div>
        <div class="ItemCateg autres" @click="getEventFilter('Autres')" :class="categ == 'Autres' ? 'ItemCategActive' : ''">
            <h3>Autres</h3>
        </div>
    </section>
    <section class="evenement" v-if="events">
      <div class="containerEvent" v-for="data in events" v-bind:key="data.id">
        <CardEvents
          :id="data.id"
          :Nom="data.Nom"
          :Img="data.Img"
          :Date="data.Date"
          :Type="data.Type"
          :idCourses="data.idCourses"
          :Lien="data.Lien"
          :Lieu="data.Lieu"
          :Details="data.Details"
          :Eveil="data.Eveil"
          :Poussins="data.Poussins"
          :Benjamins="data.Benjamins"
          :Minimes="data.Minimes"
          :Cadets="data.Cadets"
          :Fond="data.Fond"
          :Running="data.Running"
          :Marche="data.Marche"
          :Toutes="data.Toutes"
          :Autres="data.Autres"
        />
      </div>
    </section>
</template>

<style scoped>
.text_titre {
  width: 30%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 5%;
  text-align: center;
  border: 5px solid #fdce08;
}
.ListCategories{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
    justify-content: space-around;
}
.ItemCateg{
    width: 18%;
    border-radius: 10px;
    margin-right: 1%;
    margin-bottom: 2%;
    cursor: pointer;
}
.Tous{
  background-color: #dddddd;
}
.ItemCategActive{
    border: 4px solid #000;
}
.ItemCateg h3{
    padding: 4%;
    margin: 0;
    text-align: center;
}

.evenement{
    width: 80%;
    margin: auto;
    margin-top: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.containerEvent{
  width: 30%;
  margin-bottom: 2%
}
.autres{
  background-color: #bdbdbd;
}
@media (min-width: 1024px) and (max-width: 1300px){
  .ItemCateg{
    width: 20%;
  }
}
@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
  .evenement{
    width: 86%;
  }
  .ItemCateg{
    width: 46%;
    align-self: center;
  }
  .Tous{
    width: 100% !important;
  }
  .containerEvent{
    width: 94%;
    margin-bottom: 2%;
  }
}
</style>
