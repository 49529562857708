<script>
import { defineComponent, ref } from 'vue'
import CardEvents from '@/components/CardEvents.vue'
import EventsService from '../services/events.service.js'
import AlbumsService from '../services/albums.service.js'
import ArticlesService from '../services/articles.service.js'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

export default defineComponent({
  setup () {
    const articles = ref(null)
    ArticlesService.getNextArticles().then(
      (response) => {
        articles.value = response.data
      },
      (error) => {
        events.value =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString()
      }
    )

    const events = ref(null)
    EventsService.getEventsNext().then(
      (response) => {
        events.value = response.data
      },
      (error) => {
        events.value =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString()
      }
    )

    const albums = ref(null)
    AlbumsService.getNextAlbums().then(
      (response) => {
        albums.value = response.data
      },
      (error) => {
        albums.value =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString()
      }
    )
    function getImageUrl (name) {
      return new URL(`/src/assets/${name}`, import.meta.url).href
    }
    return {
      events,
      articles,
      albums,
      getImageUrl,
      moment
    }
  },
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Autoplay',
  components: {
    Carousel,
    Slide,
    Navigation,
    CardEvents
  }
})
</script>

<template>
  <section class="home_header">
    <div class="left">
      <Carousel :autoplay="5000" :wrap-around="true" class="containerCarousel">
        <Slide v-for="data in articles" :key="data" class="containerSlide">
          <img  :src="data.Img" alt="" class="img_carousel">
          <span class="containerSlideText"></span>
          <div class="slideText">
              <h2>{{ data.Titre }}</h2>
              <router-link class="lireArticle" to="/news">
                <h3>Lire l'article</h3>
              </router-link>
            </div>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </Carousel>
      <div class="containerReseaux">
        <img class="imgOGSA" src="@/assets/OGSADosTest.png" alt="">
        <div class="creation">
          <h3>Crée en 1965</h3>
          <h3>Club FFA</h3>
          <h3>Stade J.Deconinck</h3>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="foulees">
        <router-link to="/foulees">
          <img src="@/assets/jzxkvg7f0lpdxadtsja2.jpg" alt="">
          <div class="containerShadowFoulees">
            <div>
              <h2>Les 14èmes Foulées du Stadium</h2>
              <h3>Dimanche 06 Avril 2025</h3>
            </div>
          </div>
        </router-link>
      </div>
      <div class="containerRight">
        <div class="containerMore">
          <router-link to="/entrainement">
            <img src="@/assets/background/ysiqauci1cctqegrwxon.jpg" alt="">
            <div class="containerShadowRight">
              <h2>Entraînements</h2>
            </div>
          </router-link>
        </div>
        <div class="containerMore">
          <router-link to="/challenge">
            <img src="@/assets/277768968_3115790665342020_2862745225021976835_n.jpg" alt="">
            <div class="containerShadowRight">
              <h2>Le Challenge</h2>
            </div>
          </router-link>
        </div>
        <div class="containerMore">
          <router-link to="/events">
            <img src="@/assets/xmlsuariqxdw1la4svwj.jpg" alt="">
            <div class="containerShadowRight1">
              <h2>Les évènements</h2>
            </div>
          </router-link>
        </div>
        <div class="containerMore">
          <router-link to="/galeries">
            <img src="@/assets/background/IMG_2777_mze4xw.jpg" alt="">
            <div class="containerShadowRight1">
              <h2>La Galerie</h2>
            </div>
          </router-link>
        </div>
      </div>
        <!--<router-link to="/events">
          <div class="TextContainerRight">
            <h2>Nos événements</h2>
          </div>
        </router-link>
        <img class="img_right" src="@/assets/xmlsuariqxdw1la4svwj.jpg" alt="">
      </div>
      <div class="container_right">
        <router-link to="/challenge">
          <div class="TextContainerRight">
            <h2>Le challenge</h2>
          </div>
        </router-link>
        <img class="img_right" src="@/assets/277768968_3115790665342020_2862745225021976835_n.jpg" alt="">-->
    </div>
  </section>
  <section>
    <div class="text_titre">
      <h1>évènements à venir</h1>
    </div>
    <div class="evenement">
      <div v-for="data in events" :key="data.id" class="containerEvent">
        <CardEvents
          :id="data.id"
          :Nom="data.Nom"
          :Img="data.Img"
          :Date="data.Date"
          :Type="data.Type"
          :idCourses="data.idCourses"
          :Lien="data.Lien"
          :Lieu="data.Lieu"
          :Details="data.Details"
          :Eveil="data.Eveil"
          :Poussins="data.Poussins"
          :Benjamins="data.Benjamins"
          :Minimes="data.Minimes"
          :Cadets="data.Cadets"
          :Fond="data.Fond"
          :Running="data.Running"
          :Marche="data.Marche"
          :Toutes="data.Toutes"
          :Autres="data.Autres"
        />
      </div>
    </div>
  </section>
  <section class="containerAlbums">
    <div class="text_titre">
      <h1>Derniers albums photos</h1>
    </div>
    <section class="galerie" v-if="albums">
      <div v-for="data in albums" :key="data.id" class="album">
        <router-link :to="'/albums/' + data.id">
          <img class="img_album" :src="data.Image" alt="" />
          <h1>{{ data.Nom }}</h1>
        </router-link>
      </div>
    </section>
  </section>
  <section class="containerRejoignez">
    <h2>Rejoignez-nous sur les Réseaux Sociaux</h2>
    <div class="containerMesReseaux">
      <a target="_blank" href="https://www.facebook.com/people/Ogs-athl%C3%A9tisme/100052240903310/" class="mesReseaux">
        <img class="fb" src="@/assets/fFacebook.png" alt="">
        <h3>Facebook</h3>
      </a>
      <a target="_blank" href="https://www.instagram.com/ogsathletisme/" class="mesReseaux">
        <img class="inst" src="@/assets/instagram.png" alt="">
        <h3>Instagram</h3>
      </a>
    </div>
  </section>
  <section class="containerPartenaires">
    <div class="text_titre">
      <h1>Nos partenaires</h1>
    </div>
    <div class="partenaires">
      <img src="@/assets/partenaires/logo-mcm-website.png" alt="">
      <img src="@/assets/partenaires/running.png" alt="">
      <img src="@/assets/partenaires/dunes.png" alt="">
      <img src="@/assets/partenaires/eau.png" alt="">
    </div>
  </section>
  <section class="containerLiens">
    <div class="text_titre">
      <h1>Liens utiles</h1>
    </div>
    <div class="liens">
      <a target="_blank" href="http://cdnord.athle.com/">
        <img src="@/assets/cdnord.png" alt="">
      </a>
      <a target="_blank" href="http://cdnord.athle.com/">
        <img src="@/assets/lhdfa.png" alt="">
      </a>
      <a target="_blank" href="https://bases.athle.fr/asp.net/accueil.aspx?frmbase=bilans">
        <img src="@/assets/base.png" alt="">
      </a>
      <a target="_blank" href="https://bases.athle.fr/asp.net/accueil.aspx?frmbase=bilans">
        <img src="@/assets/athlelive.jpg" alt="">
      </a>
    </div>
  </section>
  <section>
    <div class="text_titre">
      <h1>Nous contacter</h1>
    </div>
      <div class="bottom">
        <div class="contactez">
          <div class="containerContact">
            <div class="containerContactIcon">
              <img src="@/assets/place.png" alt="">
            </div>
            <div class="containerContactText">
              <h2>Adresse</h2>
              <h3>Stade J.Deconinck, Grande-Synthe</h3>
            </div>
          </div>
          <div class="containerContact">
            <div class="containerContactIcon">
              <img src="@/assets/mail.png" alt="">
            </div>
            <div class="containerContactText">
              <h2>Mail</h2>
              <h3>ogsathletisme59@gmail.com</h3>
            </div>
          </div>
          <div class="containerContact">
            <div class="containerContactIcon">
              <img src="@/assets/logo-messenger-blanc.png" alt="">
            </div>
            <div class="containerContactText">
              <h2>Messenger</h2>
              <h3>ogs athlétisme</h3>
            </div>
          </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2509.9687330162074!2d2.2966076157379343!3d51.01672647955756!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47dc8aef2511fa93%3A0xe1be8fd726d4886a!2sRue%20Ren%C3%A9-Antoine%20Ferchault%20de%20R%C3%A9aumur%2C%2059760%20Grande-Synthe!5e0!3m2!1sfr!2sfr!4v1675892512399!5m2!1sfr!2sfr" class="iframe" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
  </section>
</template>

<style scoped>
.home_header {
  display: flex;
  flex-wrap: wrap;
}
.left {
  width: 50%;
  display: flex;
  flex-direction: column;
}
.containerCarousel{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.carousel__icon{
  color: #fff !important
}
.containerSlide{
  width: 100%;
}
.img_carousel{
  width: 100%;
  display: block;
  object-fit: cover;
  height: 55vh;
  opacity: 1;
}
.containerSlideText{
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  top: 10%;
  height: 56vh;
  bottom: 10%;
  padding: 2%;
  font-size: 150%;
  background-color: #1a12128c;
  align-items: center;
  align-self: center
}
.slideText{
  position: absolute;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
}
.slideText h2{
  color: #fff;
  font-size: 250%;
  width: 100%;
}
.lireArticle{
  width: 30%;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-decoration: none;
  padding: 2%;
  border: 3px solid #fff;
  border-radius: 5px;
}
.lireArticle h3{
  margin: 0;
  color: #fff
}
.containerReseaux{
  width: 100%;
  height: 25vh;
  background-color: #0872b1;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.imgOGSA{
  width: 35%;
  height: 22vh;
  object-fit: contain;
  align-self: center;
}
.creation{
  width: 60%;
  align-self: center;
  color: #fff;
  text-transform: uppercase
}
.containerReseaux h2{
  color: #fff;
}
.containerSuivezNous{
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  margin-top: -2%;
}
.iconSuivezNous{
  width: 40px;
  height: 40px;
  margin-left: 2%;
}
.TextContainerRight{
  position: absolute;
  width: 30%;
  background-color: #1a12128c;;
  color: #fff;
  font-size: 150%;
  margin-top: 8%;
  margin-left: 8%;
  text-align: center;
}
.right{
  width: 50%;
  display: flex;
  flex-direction: column;
}
.container_right{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.foulees{
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: column;
}
.foulees img{
  width: 100%;
  object-fit: cover;
  height: 20vh;
}
.containerShadowFoulees{
  position: absolute;
  width: 50%;
  height: 20vh;
  background-color: #1a12128c;
  top: 12.4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center
}
.containerShadowFoulees div{
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4%;
}
.containerShadowFoulees h2{
  color: #fff;
  font-family: 'ArquitectaBlack';
  font-size: 220%;
  margin: 0;
  letter-spacing: 4px;
}
.containerShadowFoulees h3{
  color: #fff;
  margin: 0;
  font-size: 150%
}
.containerRight{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.containerMore{
  width: 50%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}
.containerMore a{
  width: 100%;
}
.containerMore img{
  width: 100%;
  object-fit: cover;
  height: 30vh;
}
.containerMore h2{
  color: #fff;
  text-transform: uppercase;
  margin-top: 28%;
}
.containerShadowRight{
  position: absolute;
  width: 25%;
  height: 30vh;
  background-color: #1a12128c;
  top: 32.2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center
}
.containerShadowRight1{
  position: absolute;
  width: 25%;
  height: 30vh;
  background-color: #1a12128c;
  top: 62.2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center
}
.img_right{
  width: 100%;
  height: 35.8vh;
  object-fit: cover;
}
.containerAlbums{
}

.evenement{
    width: 80%;
    margin: auto;
    margin-top: 2%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}
.containerEvent{
  width: 30%;
}
.galerie {
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.album {
  display: flex;
  flex-direction: column;
  width: 30%;
}
.album > h1 {
  font-size: 150%;
  color: #0872b1;
}
.album a{
  text-decoration: none;
  color: #0872b1;
}
.img_album{
  width: 100%;
  height: 28vh;
  object-fit: cover;
}
.containerRejoignez{
    width: 96%;
    height: 200px;
    margin-top: 2%;
    border-radius: 15px;
    background-color: #1f2937;
    padding: 2%;
}
.containerRejoignez h2{
  text-align: center;
  color: #fff;
  font-size: 250%;
}
.containerMesReseaux{
  width: 25%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.mesReseaux{
  width: 40%;
  text-decoration: none;
  background-color: #0872b1;
  color: #fff;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.partenaires{
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin-top: 2%;
}
.partenaires img{
  object-fit: contain;
  width: 22%;
}
.liens{
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 2%;
  align-items: center
}
.liens a{
  width: 20%;
}
.liens a:nth-child(1) img{
  width: 70%;
}
.liens img{
  width: 100%;
  object-fit: contain;
}
.bottom{
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: -4%
}
.contactez{
  width: 50%;
  height: 400px;
  background-color: #1f2937;
  display: flex;
  flex-wrap: wrap;
  padding: 2%;
}
.containerContact{
  width: 60%;
  display: flex;
  flex-direction: row;
  margin-top: 4%;
  margin-left: 30%;
}
.containerContactIcon{
  width: 40px;
  height: 40px;
  padding: 1%;
  background-color: #0872b1;
  border-radius: 10px;
  align-items: center;
  margin-right: 4%;
}
.containerContactIcon img{
  object-fit: contain;
  width: 100%;
}
.containerContactText{
  display: flex;
  flex-direction: column;
}
.containerContactText h2{
  margin: 0;
  color: #fff;
}
.containerContactText h3{
  margin: 0;
  margin-top: 4%;
  color: #a19e98;
  font-weight: 100
}
.fb{
  width: 10%;
}
.inst{
  width: 15%;
}
.iframe{
  width: 46%;
}

@media screen and (min-width : 1850px) {

.training {
    width: 72%;
}
.containerSlideText{
    font-size: 130%;
    height: 60vh;
  }
  .img_carousel{
    height: 60vh;
  }
  .containerShadowFoulees{
    top: 9.5vh;
  }
  .containerShadowFoulees div{
    margin-top: 6%;
  }
  .containerShadowRight{
    top: 29.5vh;
  }
  .containerShadowRight1{
    top: 59.5vh
  }
  .containerReseaux{
    height: 20vh;
  }

}
@media (min-width: 1024px) {

  .img_right{
    height: 39.7vh;
  }
  .containerSuivezNous{
    width: 40%;
  }
}

@media screen and (max-width : 1024px) {
  .home_header{
    display: flex;
    flex-direction: column;
  }
  .containerSlideText{
    font-size: 100%;
  }

  .containerSlideText div{
    margin-top: 30%;
    font-size: 120%;
    width: 90%;
  }
  .lireArticle{
    width: 60%;
  }
  .containerReseaux{
    height: 24vh;
  }
  .info{
    font-size: 96%;
  }
  .place{
    margin-left: 0%;
    margin-left: 12%;
  }
  .follower{
    width: 90%;
    margin-left: 12%;
  }
  .facebook_img {
    width: 15%;
    margin-left: 5%;
  }
  .instagram_img{
    width: 15%;
    margin-left: 2%;
  }
  .left{
    width: 100%;
  }

  .right{
    display: none
  }
  .actu_text {
    top: 130px;
    text-align: center;
  }
  .containerEvent{
    width: 94%;
    margin-bottom: 2%;
  }
  .text_titre {
    width: 80%;
    margin: auto;
    margin-top: 8%;
    margin-bottom: 6%;
    text-align: center;
    border: 5px solid #fdce08;
  }
  .text_titre h1{
    font-size: 175%;
  }
  .info h1{
    font-size: 175%;
  }
  .entrainements_text {
    top: 650px;
    width: auto;
  }
  .evenement_text {
    top: 950px;
    width: auto;
  }
  .evenement{
    width: 86%;
  }
  .galerie {
    width: 80%;
    margin: auto;
    display: flex;
    flex-direction:  column;
  }
  .album {
    width: 100%;
  }
  .album_button {
    width: 80%;
    margin-left: 10%;
    padding: 6%;
  }
  .iframe{
    width: 100%;
    height: 400px;
  }
  .liens a{
    width: 50%;
    margin: auto;
  }
  .partenaires img{
    width: 50%;
  }
  .containerRejoignez h2{
    font-size: 180%;
  }
  .containerMesReseaux{
    width: 90%;
  }
  .contactez{
    width: 100%;
  }
  .containerContact{
    width: 80%;
    display: flex;
    flex-direction: row;
    margin-top: 4%;
    margin-left: 10%;
    font-size: 80%;
  }
  .containerContact:nth-child(1){
    margin-top: 10%;
  }
}
@media screen and (min-width : 300px) and (max-width : 960px) {
  .containerSuivezNous{
    width: 70%;
    margin-top: -2%;
  }
  .containerReseaux h2{
    font-size: 130%;
    margin-top: 7%;
  }
}
</style>
