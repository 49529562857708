import axios from 'axios'
const API_URL = 'https://ogsa-api-3d50c2c1176b.herokuapp.com/api/'

class AuthService {
  login (user) {
    return axios
      .post(API_URL + 'login', {
        user: user.user,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data))
        }

        return response.data
      })
  }

  logout () {
    localStorage.removeItem('user')
  }

  register (user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    })
  }
}

export default new AuthService()
