<script setup lang="ts">
import { ref } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

</script>

<template>
    <div class="text_titre">
        <h1>Horaire d'entraînement</h1>
    </div>
    <section class="containerEntrainement">
        <div class="containerGroupe">
            <img class="imgGroupe" src="@/assets/background/tizhbd5xliajzy4qgdeh.jpg" alt="">
            <div class="containerDetails">
                <h1 class="eveilText">Eveil Athlé</h1>
                <h2>Enfants nés entre 2016 et 2018</h2>
                <p>Mercredi de 16H00 à 17H30</p>
                <h4>Découverte des différentes activités de l'athlétisme (Courses, Sauts et Lancer)</h4>
                <h3>Encadrants :</h3>
                <h4>Elodie</h4>
            </div>
        </div>
        <div class="containerGroupe">
            <div class="containerDetails">
                <h1 class="poussinsText">Poussins</h1>
                <h2>Enfants nés entre 2014 et 2015</h2>
                <p>Mercredi de 18H00 à 19H30</p>
                <p>Vendredi de 18H00 à 19H30</p>
                <h4>Découverte des différentes activités de l'athlétisme (Courses, Sauts et Lancer)</h4>
                <h3>Encadrants :</h3>
                <h4>Elodie et Christophe</h4>
            </div>
            <img class="imgGroupe" src="@/assets/background/ysiqauci1cctqegrwxon.jpg" alt="">
        </div>
        <div class="containerGroupe">
            <img class="imgGroupe" src="@/assets/xmlsuariqxdw1la4svwj.jpg" alt="">
            <div class="containerDetails">
                <div class="benjaminsMinimes">
                    <h1 class="benjaminsText">Benjamins/</h1><h1 class="minimesText">Minimes</h1>
                </div>
                <h2>Enfants nés entre 2010 et 2013</h2>
                <p>Lundi de 18H00 à 19H30</p>
                <p>Mardi de 18H00 à 19H30</p>
                <p>Jeudi de 18H00 à 19H30</p>
                <p>Samedi de 10H00 à 12H00</p>
                <h4>Découverte des différentes activités de l'athlétisme (Courses, Sauts et Lancer)</h4>
                <h3>Encadrants :</h3>
                <h4>Elodie, Sghaier et Benoit</h4>
            </div>
        </div>
        <div class="containerGroupe">
            <div class="containerDetails">
                <h1 class="cadetsText">Sprint</h1>
                <h2>Personnes nées à partir de 2009 et avant...</h2>
                <p>Lundi de 18H00 à 20H00</p>
                <p>Mardi de 18H00 à 20H00</p>
                <p>Jeudi de 18H00 à 20H00</p>
                <p>Dimanche de 10H00 à 12H00</p>
                <h4>Perfectionnement dans le sprint (60m/100m/200m/400m)</h4>
                <h3>Encadrants :</h3>
                <h4>Benoit</h4>
            </div>
            <img class="imgGroupe" src="@/assets/background/20240609_133317.dng.jpg" alt="">
        </div>
        <div class="containerGroupe">
            <img class="imgGroupe" src="@/assets/background/20240609_152124.jpg" alt="">
            <div class="containerDetails">
                <h1 class="fondText">Demi-Fond</h1>
                <h2>Personnes nées à partir de 2009 et avant...</h2>
                <p>Mardi de 18H00 à 20H00</p>
                <p>Jeudi de 18H00 à 20H00</p>
                <p>Samedi de 14H00 à 16H30</p>
                <h4>Perfectionnement dans le demi-fond (800m/1500m/3000m/5000m)</h4>
                <h3>Encadrants :</h3>
                <h4>Marcel</h4>
            </div>
        </div>
        <div class="containerGroupe">
            <div class="containerDetails">
                <h1 class="runningText">Running</h1>
                <h2>Personnes nées à partir de 2009 et avant...</h2>
                <p>Mardi de 18H00 à 20H00</p>
                <p>Jeudi de 18H00 à 20H00</p>
                <h4>Découverte et perfectionnement dans les courses (5km et plus...)</h4>
                <h3>Encadrants :</h3>
                <h4>Olivier</h4>
            </div>
            <img class="imgGroupe" src="@/assets/background/jzxkvg7f0lpdxadtsja2.jpg" alt="">
        </div>
        <div class="containerGroupe">
            <img class="imgGroupe" src="@/assets/background/image_0902214_20240414_ob_b041f7_img-8822.jpeg" alt="">
            <div class="containerDetails">
                <h1 class="marcheText">Marche Nordique</h1>
                <h2>Toutes catégories</h2>
                <p>Mercredi de 18H00 à 19H30</p>
                <p>Dimanche de 09H30 à 11H30</p>
                <h4>Initiation, pratique et perfectionnement de la marche nordique</h4>
                <h3>Encadrants :</h3>
                <h4>Eric, Christophe et Magareth</h4>
                <br/>
                <a href="https://www.marchenordique-ogsa.fr/">https://www.marchenordique-ogsa.fr/</a>
            </div>
        </div>
    </section>
</template>

<style scoped>
.text_titre {
    width: 30%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 5%;
    text-align: center;
    border: 5px solid #fdce08;
}
.containerEntrainement {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
}
.containerGroupe {
    width: 100%;
    height: 400px;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    margin-bottom: 2%;
    border-radius: 5px;
}
.imgGroupe {
    width: 50%;
    object-fit: cover;
    border-radius: 5px;
}
.containerDetails {
    width: 50%;
    text-align: center;
    align-self: center;
}
.containerDetails h1{
    font-size: 400%;
    font-family: 'ArquitectaBlack';
    margin-top: 0;
    margin-bottom: 0;
}
.benjaminsMinimes{
    width: 88%;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.containerDetails p {
    text-decoration: underline;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 1%;
}
.containerDetails h3 {
    text-decoration: underline;
    margin-bottom: 0;
}
.containerDetails h4{
    width: 70%;
    margin: auto;
    margin-top: 2%;
    font-weight: 100;
}
@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
  .containerGroupe{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    font-size: 80%;
    margin-bottom: 4%;
  }
  .benjaminsMinimes{
    width: 88%;
    margin: auto;
    display: flex;
    flex-direction: column
  }
  .containerGroupe:nth-child(2){
    flex-direction: column-reverse;
  }
  .containerGroupe:nth-child(4){
    flex-direction: column-reverse;
  }
  .containerGroupe:nth-child(6){
    flex-direction: column-reverse;
  }
  .imgGroupe{
    width: 100%;
    height: 200px;
    align-items: flex-start
  }
  .containerDetails{
    width: 100%;
    height: auto;
    padding: 4%;
  }
  .containerDetails h2{
    width: 80%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }
}
</style>
