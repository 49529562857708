<script setup lang="ts">
import { ref } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

</script>

<template>
    <div class="text_titre">
        <h1>Tarifs 2025-2026</h1>
    </div>
    <section class="containerTarifs">
        <div class="tarif eveil">
            <h2>Eveil Athlé</h2>
            <h3>Enfants nés entre 2016 et 2018</h3>
            <h4>Cotisation : 60€/50€*</h4>
        </div>
        <div class="tarif poussins">
            <h2>Poussins</h2>
            <h3>Enfants nés entre 2014 et 2015</h3>
            <h4>Cotisation : 60€/50€*</h4>
        </div>
        <div class="tarif benjamins">
            <h2>Benjamins</h2>
            <h3>Enfants nés entre 2012 et 2013</h3>
            <h4>Cotisation : 65€/55€*</h4>
        </div>
        <div class="tarif minimes">
            <h2>Minimes</h2>
            <h3>Enfants nés entre 2010 et 2011</h3>
            <h4>Cotisation : 65€/55€*</h4>
        </div>
        <div class="tarif cadets">
            <h2>Cadets/Juniors</h2>
            <h3>Personnes nées entre 2006 et 2009</h3>
            <h4>Cotisation : 70€/60€*</h4>
        </div>
        <div class="tarif fond">
            <h2>Espoirs</h2>
            <h3>Personnes nées entre 2003 et 2005</h3>
            <h4>Cotisation : 90€/75€*</h4>
        </div>
        <div class="tarif running">
            <h2>Seniors/Masters</h2>
            <h3>Personnes nées à partir de 2002 et avant...</h3>
            <h4>Cotisation : 100€/85€*</h4>
        </div>
        <div class="tarif running">
            <h2>Running**</h2>
            <h3>Personnes nées à partir de 2002 et avant...</h3>
            <h4>Cotisation : 80€/70€*</h4>
        </div>
        <div class="tarif marche">
            <h2>Marche nordique</h2>
            <h3>Tout âge </h3>
            <h4>Cotisation : 70€/60€*</h4>
        </div>
    </section>
    <section class="informationPrix">
        <h3>* Prix de la licence si renouvellement (licencié en 2023-2024)</h3>
        <h3>** Licence running : insriptions aux seules courses du Challenge des Dunes de Flandre (pas de course à label).</h3>
        <h3>L'accès aux installations du club est réservé aux membres à jour dans leur cotisation.</h3>
        <h3>- Les licences 2023-2024 ne sont plus valides pour les compétitions à partir du 1er septembre 2024.</h3>
        <h3>- Changement de catégories le 1er septembre 2024.</h3>
        <h3>Le Pass'sport de la ville et de l'état sont acceptés.</h3>
        <h3>Les coupons-sport ANCV sont acceptés <b>avec une majoration de 5€</b> (frais de gestion ANCV) </h3>
    </section>
</template>

<style scoped>
.text_titre {
    width: 30%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 5%;
    text-align: center;
    border: 5px solid #fdce08;
}
.containerTarifs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin: auto;
}
.tarif{
    width: 30%;
    height: 200px;
    margin-bottom: 3%;
    border-radius: 5px;
    color: #000;
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
}
.tarif h2{
    font-family: 'ArquitectaBlack';
    font-size: 250%;
    margin-bottom: 0;
}
.tarif h3{
    width: 70%;
    margin: auto;
    font-weight: 100;
}
.tarif h4{
    margin-top: 0;
}
.informationPrix{
    width: 80%;
    margin: auto;
}
.informationPrix h3{
    font-weight: 100;
    color: #000;
}
@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
  .tarif{
    width: 100%;
  }
}
</style>
