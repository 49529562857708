<script setup lang="ts">
import CoureursService from '@/services/coureur.service.js'
import CardCategories from '@/components/CardCategories.vue'
import { ref } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/fr'
import router from '@/router'
import { useRoute } from 'vue-router'
moment.locale('fr')

const licencie = ref(null)
const route = useRoute()
const tab = ref('home')

CoureursService.getOneLicencies(route.params.id)
  .then(
    (response) => {
      licencie.value = response.data
    },
    (error: any) => {
      if (error.response.status === 401) {
        router.push('/login')
      }
    }
  )

function getTabs (value: string) {
  tab.value = value
}

</script>
<template>
    <div class="containerHeaderLicencies" v-if="licencie">
      <h1>Information licencié</h1>
      <div class="containerHeaderButton">
        <button class="buttonEditAthlete">
          <img class="iconList" src="@/assets/icons/download_white.png" alt="">
          <span>Modifier l'athlète</span>
        </button>
        <button class="buttonDeleteAthlete">
          <img class="iconList" src="@/assets/icons/add_white.png" alt="">
          <span>Supprimer l'athlète</span>
        </button>
      </div>
    </div>
    <section class="containerProfil" v-if="licencie">
      <div class="containerIdentite">
        <div class="containerIdentiteLeft">
          <img class="imgProfil" src="@/assets/default.jpg" alt="">
          <div class="containerIdentiteDetails">
            <div class="containerNom">
              <h2>{{ licencie.Nom }} {{ licencie.Prenom }}</h2>
              <div>
                <CardCategories :date="licencie.Naissance"/>
              </div>
            </div>
            <h3>N°licence : {{ licencie.NumLic_Coureur }}</h3>
          </div>
        </div>
        <div class="tabsList">
          <button @click="getTabs('home')" :class="tab == 'home' ? 'buttonTabsActive' : 'buttonTabs'">Générales</button>
          <button @click="getTabs('result')" :class="tab == 'result' ? 'buttonTabsActive' : 'buttonTabs'">Résultats</button>
          <button @click="getTabs('history')" :class="tab == 'history' ? 'buttonTabsActive' : 'buttonTabs'">Historique</button>
        </div>
      </div>
      <div class="containerGenerales" v-if="tab === 'home'">
        <div>
          <img src="@/assets/icons/view.png" alt="">
          <div>
            <h3>peutevynckeurope.benoit@gmail.com</h3>
            <h4>Adresse mail</h4>
          </div>
        </div>
      </div>
  </section>
</template>
<style>
.containerHeaderLicencies{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1%;
    }
    .containerHeaderButton{
      width: 40%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
    .buttonDeleteAthlete{
      width: 40%;
      height: 50px;
      background-color: #ff0000;
      border-radius: 15px;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }
    .buttonEditAthlete img{
      width: 15%;
      align-self: center;
    }
    .buttonEditAthlete{
      width: 40%;
      height: 50px;
      background-color: #407EC9;
      border-radius: 15px;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      margin-right: 1%;
    }
    .containerProfil{
      width: 96%;
    }
    .imgProfil{
      width: 100px;
      height: 100px;
      border-radius: 100px;
      margin-right: 2%;
      align-self: center
    }
    .containerIdentite{
      width: 100%;
      padding: 2%;
      background-color: #fff;
      border-radius: 15px;
      align-self: center;
      margin-bottom: 2%
    }
    .containerIdentiteLeft{
      width: 60%;
      display: flex;
      align-self: center;
    }
    .containerIdentiteDetails {
      width: 60%;
      align-self: center
    }
    .containerNom{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .containerNom h2{
      margin-right: 2%;
      margin-top: 0;
      margin-bottom: 0;
    }
    .containerIdentiteDetails h3{
      margin-bottom: 0
    }
    .tabsList{
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 2%
    }
    .tabsList button{
      margin-right: 2%
    }
    .buttonTabs{
      border: none;
      background-color: transparent;
      font-size: 100%;
      font-weight: bold
    }
    .buttonTabsActive{
      padding: 1%;
      background-color: #407EC9;
      border: none;
      border-radius: 10px;
      color: #fff;
      font-size: 100%;
      font-weight: bold
    }
    .containerGenerales{
      width: 100%;
      height: 50vh;
      background-color: #fff;
      padding: 2%;
      border-radius: 15px;
    }
</style>
