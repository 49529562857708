<script setup lang="ts">

import CoursesService from '../services/courses.service.js'
import moment from 'moment'
import 'moment/dist/locale/fr'
import router from '@/router'
import { ref } from 'vue'
moment.locale('fr')

const courses = ref()
CoursesService.getAllCourses().then(
  (response: { data: any }) => {
    courses.value = response.data
  },
  (error: any) => {
    courses.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

const courseNext = ref()
CoursesService.getNextCourse().then(
  (response: { data: any }) => {
    courseNext.value = response.data
  },
  (error: any) => {
    courseNext.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

function getImageUrl (name: any) {
  return '/Flyers/' + name
}
function getImageUrlBack (name: any) {
  return '/Background/' + name
}
</script>

<template>
    <div class="text_titre">
        <h1>Les courses du challenge</h1>
    </div>

    <h3 class="textAlert">Inscriptions aux courses du challenge pour les licenciés du club uniquement</h3>

    <section class="containerNext" v-if="courseNext">
        <div class="nextCourse">
            <h3>à la une</h3>
            <h2>{{ courseNext.Nomcourse_courses }}</h2>
            <div class="cardNextCourse">
                <img :src="courseNext.Background" class="imgBackground" alt="">
                <div class="descNextCourse">
                    <h4>{{ moment(courseNext.Datecourse_courses).format('dddd DD MMMM YYYY') }}</h4>
                    <h5>{{ courseNext.Ville }}</h5>
                    <ul v-if="courseNext.parcourirs">
                        <li v-for="parcours in courseNext.parcourirs" v-bind:key="parcours.id">{{ moment(parcours.Heure, 'HH:mm:ss').format('HH') }}H{{ moment(parcours.Heure, 'HH:mm:ss').format('mm') }}  {{ parcours.Nom }} - {{ parcours.Distance }}Km</li>
                    </ul>
                    <div class="containerButton">
                        <RouterLink class="butStyle" :to="{ name: 'course', params: {  id: courseNext.id} }" v-if="moment().format('YYYY-MM-DD') <= moment(courseNext.Datecourse_courses).subtract(10, 'days').format('YYYY-MM-DD')">S'inscrire</RouterLink>
                        <RouterLink class="butStyle" :to="{ name: 'inscrit', params: {  id: courseNext.id} }">Liste des inscrits</RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="containerCourses" v-if="courses">
        <h3>Prochainement</h3>
        <h2>Les courses du challenge 2025</h2>
        <div class="containerListCourse" v-if="courses">
            <div v-for="data in courses" v-bind:key="data.id" class="cardCourse">
                <img :src="data.Background" alt="">
                <div class="descCourse">
                    <h4>{{ data.Nomcourse_courses }}</h4>
                    <h5>{{ moment(data.Datecourse_courses).format('dddd DD MMMM YYYY') }}</h5>
                    <h6>{{ data.Ville }}</h6>
                </div>
                <RouterLink class="butStyleCourse" :to="{ name: 'course', params: {  id: data.id} }" v-if="moment().format('YYYY-MM-DD') <= moment(data.Datecourse_courses).subtract(10, 'days').format('YYYY-MM-DD')">S'inscrire</RouterLink>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .textAlert{
        color: #ff0000;
        text-align: center;
    }
    .containerNext{
        width: 96%;
        height: 70vh;
        margin-top: 4%;
        padding: 2%;
        background-color: #d9d9d9;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
    }
    .nextCourse{
        width: 80%;
        height: 40vh;
        padding: 1%;
        display: flex;
        flex-direction: column;
    }
    .nextCourse h3{
        margin: 0;
        text-transform: uppercase;
        color: #0872b1
    }
    .nextCourse h2{
        margin: 0;
        text-transform: uppercase;
        font-size: 300%;
        color: #000;
        font-family: 'ArquitectaBlack';
    }
    .cardNextCourse{
        width: 100%;
        margin-top: 2%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .imgBackground{
        width: 46%;
        height: 50vh;
        object-fit: cover;
        border-radius: 5px;
    }
    .descNextCourse{
        width: 52%;
    }
    .descNextCourse h4{
        font-size: 200%;
        text-transform: uppercase;
        font-family: 'ArquitectaBlack';
        color: #000;
        margin-top: 2%;
        margin-bottom: 2%
    }
    .descNextCourse h5{
        font-size: 150%;
        margin: 0;
    }
    .descNextCourse ul{
        height: 25vh;
    }
    .descNextCourse li{
        list-style: none;
    }
    .containerButton{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: 2%;
    }
    .butStyle{
        width: 40%;
        padding: 2%;
        text-decoration: none;
        color: #0872b1;
        font-size: 130%;
        border-radius: 5px;
        border: 2px solid #0872b1;
        text-align: center;
        align-self: center;
        text-transform: uppercase;
    }
    .containerCourses{
        width: 80%;
        margin: auto;
        margin-top: 4%;
    }
    .containerCourses h3{
        margin: 0;
        text-transform: uppercase;
        color: #0872b1
    }
    .containerCourses h2{
        margin: 0;
        text-transform: uppercase;
        font-size: 300%;
        color: #000;
        font-family: 'ArquitectaBlack';
    }
    .containerListCourse{
        width: 100%;
        margin-top: 2%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .cardCourse{
        width: 30%;
    }
    .cardCourse img{
        width: 100%;
        height: 30vh;
        object-fit: cover;
        border-radius: 5px;
    }
    .descCourse{
        width: 100%;
        height: 20vh;
        margin-top: 2%;
    }
    .descCourse h4{
        margin: 0;
        font-size: 150%;
        font-family: 'ArquitectaBlack';
        color: #0872b1;
        text-transform: uppercase;
    }
    .descCourse h5{
        font-size: 120%;
        text-transform: uppercase;
        margin: 0;
        margin-top: 2%;
        color: #000;
    }
    .descCourse h6{
        font-size: 120%;
        margin: 0;
        margin-top: 2%;
        margin-bottom: 5%;
    }
    .butStyleCourse{
        width: 40%;
        padding: 2%;
        text-decoration: none;
        color: #000;
        font-size: 130%;
        border-radius: 5px;
        border: 2px solid #000;
        text-align: center;
        text-transform: uppercase;
        margin-left: 60%;
    }
    @media screen and (min-width : 1850px) {
        .descCourse{
            height: 14vh;
        }
        .butStyleCourse{
            margin-left: 70%;
        }
    }
    @media screen and (max-width : 1024px) {
        .text_titre {
            margin-top: 5%;
            width: 80%;
            font-size: 80%;
        }
        .containerNext{
            height: 100vh;
        }
        .nextCourse{
            margin-top: 5%;
        }
        .nextCourse h2{
            font-size: 200%
        }
        .cardNextCourse{
            margin-top: 5%;
        }
        .imgBackground{
            width: 100%;
            height: 25vh;
        }
        .descNextCourse{
            width: 100%;
            margin-top: 4%;
        }
        .descNextCourse ul {
            padding: 0;
        }
        .butStyle{
            width: 100%;
            margin-bottom: 4%;
        }
        .containerCourses{
            margin-top: 6%
        }
        .containerCourses h2{
            font-size: 200%;
        }
        .containerListCourse{
            margin-top: 5%;
        }
        .cardCourse{
            width: 100%;
            margin-bottom: 15%
        }
        .descCourse{
            height: 15vh;
        }
    }
</style>
