<script setup lang="ts">

import CoureursService from '@/services/coureur.service.js'
import CardCategories from '@/components/CardCategories.vue'
import { ref } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/fr'
import router from '@/router'
moment.locale('fr')

const search = ref('')
const licencies = ref(null)

CoureursService.getLicencies()
  .then(
    (response) => {
      licencies.value = response.data
    },
    (error: any) => {
      if (error.response.status === 401) {
        router.push('/login')
      }
    }
  )

function getImageUrl (name) {
  return new URL(`/src/assets/${name}`, import.meta.url).href
}

function DeleteLicencie (id) {
  if (window.confirm('Voulez-vous vraiment supprimer ce licencié ?')) {
    CoureursService.deleteLicencies(id)
      .then(response => {
        console.log('File uploaded successfully:', response.data)
      })
      .catch(error => {
        console.error('Error uploading file:', error)
      })
  }
}

function filteredList () {
  if (licencies.value != null) {
    return licencies.value.filter((element) => element.Nom.toLowerCase().startsWith(search.value.toLowerCase()) || element.Prenom.toLowerCase().startsWith(search.value.toLowerCase()) || element.NumLic_Coureur.toString().startsWith(Number(search.value)))
  } else {
    return null
  }
}
</script>
<template>
    <div class="containerHeaderLicencies">
      <h1>Licenciés du club</h1>
      <div class="containerHeaderButton">
        <button class="buttonExport">
          <img class="iconList" src="@/assets/icons/download_white.png" alt="">
          <span>Export Excel</span>
        </button>
        <button class="buttonAddAthlete">
          <img class="iconList" src="@/assets/icons/add_white.png" alt="">
          <span>Ajouter un nouveau athlète</span>
        </button>
      </div>
    </div>
    <section class="containerLicencies">
      <div class="containerFilter">
        <div class="box">
          <div class="container-1">
              <span class="icon"><img class="iconSearch" src="@/assets/icons/search.png" alt=""></span>
              <input type="search" id="search" placeholder="Recherche..." v-model="search" />
          </div>
        </div>
        <button>
          <span>Catégories</span>
          <img class="iconList" src="@/assets/icons/list.png" alt="">
        </button>
      </div>
        <h4 v-if="licencies">Total {{ licencies.length }} licenciés</h4>
        <div class="containerTable" v-if="licencies">
            <table>
                <thead>
                    <tr>
                        <th>Nom du licencié</th>
                        <th>N°Licence</th>
                        <th>Catégories</th>
                        <th>Date de naissance</th>
                        <th colspan="3">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="data in filteredList()" :key="data.id">
                        <td><img class="imgLicencies" src="@/assets/default.jpg" alt=""><span>{{data.Nom}} {{data.Prenom}}</span></td>
                        <td>{{data.NumLic_Coureur}}</td>
                        <td><CardCategories :date="data.Naissance"/></td>
                        <td>{{moment(data.Naissance).format('DD/MM/YYYY')}}</td>
                        <td>
                          <router-link :to="'/LicencieView/' + data.id"><img class="iconAction" src="@/assets/icons/view.png" alt=""></router-link>
                        </td>
                        <td>
                            <a href="" class=""><img class="iconAction" src="@/assets/icons/edit.png" alt=""></a>
                        </td>
                        <td>
                            <a href="" class=""><img class="iconAction" src="@/assets/icons/delete.png" alt=""></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<style>
    .containerLicencies{
        width: 96%;
        height: 74vh;
        background-color: #fff;
        border-radius: 15px;
        padding: 2%;
    }
    .containerTable{
      height: 60vh;
      overflow-y: auto
    }
    .containerTable table{
        width: 100%;
        border-collapse: collapse;
    }
    .containerTable thead{
        background-color: #d9d9d9;
        color: #000;
        text-align: left;
    }
    .containerTable thead th {
        position: sticky;
        top: 0%;
        height: 50px;
        background-color: #407EC9;
        font-weight: 100;
        color: yellow;
    }
    .containerTable thead th:nth-child(1) {
      border-top-left-radius: 10px;
      padding-left: 2%;
    }
    .containerTable td{
      height: 50px;
      border-bottom: 1px solid #a4a4a4;
    }
    .containerTable td:nth-child(1){
      padding: 4%;
      display: flex;
      border-left: 1px solid #a4a4a4;
    }
    .containerTable span{
      font-weight: bold;
      align-self: center;
    }
    .containerTable th:nth-child(1){
      width: 30%;
    }
    .containerTable th:nth-child(3){
      width: 15%;
    }
    .imgLicencies{
      width: 50px;
      height: 50px;
      border-radius: 50px;
      margin-right: 5%;
      margin-left: 2%
    }
    .containerFilter{
      width: 100%;
      display: flex;
    }
    .container-1{
      width: 300px;
      vertical-align: middle;
      white-space: nowrap;
      position: relative;
    }
    .container-1 input#search{
      width: 300px;
      height: 50px;
      background: #d9d9d9;
      border: none;
      font-size: 10pt;
      float: left;
      color: #63717f;
      padding-left: 45px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 15px;
    }
    .container-1 .icon{
      width: 8%;
      position: absolute;
      top: 50%;
      margin-left: 14px;
      margin-top: 14px;
      z-index: 1;
      color: #4f5b66;
    }
    .iconSearch{
      width: 100%;
    }
    .containerFilter button{
      width: 12%;
      margin-left: 1%;
      border-radius: 15px;
      background-color: transparent;
      border: 1px solid #d9d9d9;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
    .containerFilter span{
      font-size: 120%;
    }
    .iconList{
      width: 26%;
      align-self: center;
    }
    .containerHeaderLicencies{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1%;
    }
    .containerHeaderButton{
      width: 70%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }
    .buttonAddAthlete{
      width: 30%;
      height: 50px;
      background-color: #0872b1;
      border-radius: 15px;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
    }
    .buttonAddAthlete img{
      width: 15%;
      align-self: center;
    }
    .buttonExport{
      width: 18%;
      height: 50px;
      background-color: #18a323;
      border-radius: 15px;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      color: #fff;
      font-weight: bold;
      cursor: pointer;
      margin-right: 1%;
    }
    .iconAction{
      width: 35%;
    }
    .containerTable th:nth-child(5){
      width: 25%;
      border-top-right-radius: 10px
    }
    .containerTable td:nth-child(5){
      padding: 1%;
    }
    .containerTable td:nth-child(6){
      padding: 1%;
    }
    .containerTable td:nth-child(7){
      padding: 1%;
      border-right: 1px solid #d9d9d9;
    }
</style>
