<script setup lang="ts">
import { RouterLink, RouterView } from 'vue-router'
import { shallowRef } from 'vue'

const isSidebarOpen = shallowRef<boolean>(false)

const isSousMenu = shallowRef<boolean>(false)

const isSousMenu1 = shallowRef<boolean>(false)

function toggleSidebar () {
  isSidebarOpen.value = !isSidebarOpen.value
}

function toggleSousMenu () {
  isSousMenu.value = !isSousMenu.value
}

function toggleSousMenu1 () {
  isSousMenu1.value = !isSousMenu1.value
}

</script>

<template>
  <header>

    <a href="/"><img alt="Vue logo" class="logo" src="@/assets/LogoOGSAJaune.png" /></a>
    <img class="menuResponsive" @click="toggleSidebar"  src="@/assets/baseline_menu_white_48dp.png" alt="">

    <!-- #fdce08  #0872b1 -->

    <div class="wrapper">
      <nav>
        <!--<RouterLink to="/">Accueil</RouterLink>-->
                <ul id="menu-deroulant">
                  <li><RouterLink to="/">Accueil</RouterLink></li>
                  <li class="navclub">
                      <a>Le club </a>
                      <img class="imgArticle" src="@/assets/keyboard_arrow_down_24dp_FILL0_wght400_GRAD0_opsz24.png" alt="">
                      <ul class="club">
                          <!--<li>
                            <a href="/histoire">Son histoire</a>
                          </li>
                          <li>
                            <a href="/record">Nos records</a>
                          </li>-->
                          <li>
                            <RouterLink to="/comite">Le Comité</RouterLink>
                          </li>
                          <li>
                            <RouterLink  to="/entraineur">Nos Entraîneurs</RouterLink>
                          </li>
                          <!--<li>
                            <RouterLink to="/boutique">Boutique</RouterLink>
                          </li>
                          <li>
                            <a href="/entraineurs">Nos entraîneurs</a>
                          </li>-->
                          <!--<li>
                            <a href="/entraineurs">Nos officiels</a>
                          </li>-->
                          <!--<li>
                            <RouterLink to="/challenge">Le Challenge</RouterLink>
                          </li>-->
                          <!--<li>
                            <a href="/boutique">La boutique</a>
                          </li>-->
                      </ul>
                  </li>
                  <li class="navEntrainement">
                    <a href="">entrainement</a>
                    <img class="imgArticle" src="@/assets/keyboard_arrow_down_24dp_FILL0_wght400_GRAD0_opsz24.png" alt="">
                    <ul class="club">
                      <li>
                          <RouterLink  to="/entrainement">Horaires</RouterLink>
                      </li>
                      <li>
                            <RouterLink  to="/tarif">Tarifs</RouterLink>
                          </li>
                          <li>
                            <RouterLink to="/inscription">Dossier d'inscription</RouterLink>
                          </li>
                    </ul>
                  </li>
                  <!--<li><RouterLink  to="/entrainement">Entraînements</RouterLink></li>-->
                  <li><RouterLink to="/challenge">Challenge</RouterLink></li>
                  <li><RouterLink to="/news">Articles</RouterLink></li>
                  <li><RouterLink to="/galeries">Galerie</RouterLink></li>
                  <li><RouterLink to="/events">événements</RouterLink></li>
                  <li><RouterLink to="/foulees">Les Foulées 2025</RouterLink></li>
                </ul>
      </nav>
    </div>

    <div class="responsible" v-show="isSidebarOpen">
      <nav class="menu-mobile">
        <!--<RouterLink to="/">Accueil</RouterLink>-->
        <img class="menuResponsive" @click="toggleSidebar"  src="@/assets/baseline_close_white_48dp.png" alt=""/>
        <ul class="menuMobile">
          <li><RouterLink @click="toggleSidebar" to="/">Accueil</RouterLink></li>
          <li class="liSousMenu" @click="toggleSousMenu">Le club<img class="iconSousMenu" src="@/assets/keyboard_arrow_down_24dp_FILL0_wght400_GRAD0_opsz24.png" alt=""></li>
          <ul class="sousMenu" v-if="isSousMenu">
            <li><RouterLink @click="toggleSidebar" to="/comite">Comité</RouterLink></li>
            <li><RouterLink @click="toggleSidebar" to="/entraineur">Entraîneurs</RouterLink></li>
            <!--<li><RouterLink @click="toggleSidebar" to="/boutique">Boutique</RouterLink></li>-->
          </ul>
          <li class="liSousMenu" @click="toggleSousMenu1">Entraînement <img class="iconSousMenu" src="@/assets/keyboard_arrow_down_24dp_FILL0_wght400_GRAD0_opsz24.png" alt=""></li>
          <ul class="sousMenu" v-if="isSousMenu1">
            <li><RouterLink @click="toggleSidebar" to="/entrainement">Horaires</RouterLink></li>
            <li><RouterLink @click="toggleSidebar" to="/tarif">Tarifs</RouterLink></li>
            <li><RouterLink @click="toggleSidebar" to="/inscription">Dossier d'inscription</RouterLink></li>
            <!--<li><RouterLink @click="toggleSidebar" to="/boutique">Boutique</RouterLink></li>-->
          </ul>
          <li><RouterLink @click="toggleSidebar" to="/challenge">Le challenge</RouterLink></li>
          <!--<li><RouterLink @click="toggleSidebar" to="/inscription">Inscriptions</RouterLink></li>-->
          <!--<li><RouterLink @click="toggleSidebar" to="/entrainement">Entraînements</RouterLink></li>-->
          <li><RouterLink @click="toggleSidebar" to="/news">Nos articles</RouterLink></li>
          <li><RouterLink @click="toggleSidebar" to="/galeries">Galerie photos</RouterLink></li>
          <li><RouterLink @click="toggleSidebar" to="/events">&#201;vénements</RouterLink></li>
          <li><RouterLink @click="toggleSidebar" to="/foulees">Les Foulées 2024</RouterLink></li>
        </ul>

            </nav>
          </div>
  </header>

  <RouterView />

  <footer>
    <section class="footer">
      <div class="copy">
        &#169;2024 Olympique Grande-Synthe Athlétisme
      </div>
      <div class="name">
        <a href="https://www.facebook.com/people/Ogs-athl%C3%A9tisme/100052240903310/">
          <img src="@/assets/facebook.png" alt="">
        </a>
        <a href="https://www.instagram.com/ogsathletisme/">
          <img src="@/assets/Instagram_icon.png" alt="">
        </a>
      </div>
    </section>
  </footer>
</template>

<style>
@import "@/assets/base.css";
.text_titre {
  width: 30%;
  margin: auto;
  margin-top: 4%;
  margin-bottom: 2%;
  text-align: center;
  border: 5px solid #fdce08;
  text-transform: uppercase;
}
header {
  background-color: #0872b1;
  height: 90px;
  display: flex;
  flex-direction: row;
}
header a{
  width: 30%;
}
.logo {
  display: block;
  border: 0;
  width: 50%;
  margin-left: 3%
}

.wrapper {
  width: 72%;
  position: absolute;
  right: 0;
  top: 4%;
}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#menu-deroulant > li {
  color: #fff;
  text-decoration: none;
  padding-bottom: 40px;
  margin-bottom: 2%;
}
#menu-deroulant > li > a {
  text-decoration: none;
  padding-bottom: 27px;
  font-size: 110%;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  width: 16%;
  cursor: pointer;
}

.router-link-exact-active {
  width: 100px;
  border-bottom: 11px solid #fdce08;
}
.navclub{
  display: flex;
  flex-direction: row;
  width: 10%;
  cursor: pointer;
}
.navclub a{
  width: 70% !important;
}
.navclub img{
  width: 30%;
  object-fit: contain;
  margin-top: -20%;
}
.club li {
  padding: 10%;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 100%
}
.club li:hover{
  background-color: #fdce08;
}
.club li:hover a{
  color: #0872b1 !important;
}
.club .router-link-exact-active {
  border-bottom: none
}
.navEntrainement{
  display: flex;
  width: 16%;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}
.navEntrainement a{
  width: 70% !important;
}
.navEntrainement img{
  width: 18%;
  object-fit: contain;
  margin-top: -14%;
}
footer{
  margin-top: 5%;
  background-color: #595757;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1%;
  color: #fdce08;
}
.footer{
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.copy{
  width: 40%;
  align-self: center;
}
.name{
  width: 10%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}

.name img {
  width: 40px;
  height: 40px;
  vertical-align: middle;
  margin-right: 2%;
}

#menu-deroulant, #menu-deroulant ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
#menu-deroulant {
/* on centre le menu dans la page */
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#menu-deroulant ul {
/* on place les liens du menu horizontalement */

}
#menu-deroulant ul li {
/* on enlève ce comportement pour les liens du sous menu */
    display: inherit;
}
/*#menu-deroulant a {
    text-decoration: none;
    display: block;
    color: #fff;
    font-size: 150%;
}*/
#menu-deroulant ul{
    position: absolute;
/* on cache menu2 et menu3 au survol de menu1 */
    left: -999em;
    text-align: left;
    top: 60px;
    background-color: #0872b1;
    z-index: 3;
    border-top: 4px solid #fdce08;
}
#menu-deroulant li:hover ul {
/* Au survol de menu1 on replace menu2 */
    left: auto;
}
#menu-deroulant li li:hover ul {
/* Au survol de menu2 on replace menu3 */
    left: 50%;
    top:0;
}
#menu-deroulant > li > ul > li > a {
  color: #fff;
  text-decoration: none;
  font-size: 110%;
  cursor: pointer;
}
.menuResponsive{
  position: absolute;
  right: 10%;
  top: 2%;
}
.responsible{
  width: 100%;
  z-index: 3;
  height: 120vh;
  background-color: #0872b1;
  position: absolute;
}
.menu-mobile{
  display: flex;
  flex-direction: column;
}
.menu-mobile ul{
  padding: 0;
}
.menu-mobile{
  padding-left: 0;
  width: 80%;
  margin: auto;
  margin-top: 20%;
}
.menuMobile li {
  list-style: none;
  color: '#fff';
  margin-bottom: 10%;
  text-align: center;
  text-transform: uppercase;
  font-size: 200%;
  color: #fff;
}
.menuMobile .router-link-exact-active  {
  width: 100px;
  border-bottom: none;
  border-bottom: 4px solid #fdce08;
}
.menuMobile a {
  text-decoration: none;
  cursor: pointer;
  color: #fff;
}
.iconSousMenu{
  width: 15%;
}
.sousMenu li{
  font-size: 150%;
  text-align: center;
}
.liSousMenu{
  display: flex;
  align-self: center;
  justify-content: center;
}

@media (hover: hover) {
}
@media (min-width: 1024px) and (max-width: 1300px){
  .wrapper{
    width: 70%;
    top: 4.6%;
  }
  .logo{
    margin-top: 2%;
  }
}
@media (min-width: 1024px) {
  /*.responsible{
    display: none;
  }*/
  .menuResponsive{
    display: none;
  }
}

@media screen and (min-width : 1850px) {

#menu-deroulant > li > a {
  padding-bottom: 18px;
}
#menu-deroulant ul {
  top: 50px;
}
.navclub{
  width: 8%;
}
.navclub img{
  margin-top: -14%;
}
.navEntrainement{
  width: 12%;
}
.navEntrainement img{
  width: 20%;
  margin-top: -10%;
}
.logo {
  width: 40%;
}
}
@media screen and (max-width : 1024px) {
.wrapper{
  display: none;
}

.logo{
  width: 180px;
  height: 70px;
  padding: 1%;
  margin-top: 5%;
}
.footer{
  padding: 2%;
  width: 100%;
}
.copy{
  width: 70%;
}
.name{
  width: 25%;
}
.name img {
  width: 90%;
  object-fit: contain;
}
}
@media screen and (min-width : 300px) and (max-width : 960px) {

}
</style>
