import axios from 'axios'
import authHeader from './authHeader.service'

const API_URL = 'https://ogsa-api-3d50c2c1176b.herokuapp.com/api/'

class CoursesService {
  getCoureurs () {
    return axios
      .get(API_URL + 'coureurs')
  }

  getLicencies () {
    return axios
      .get(API_URL + 'licencies', { headers: authHeader() })
  }

  getOneLicencies (id) {
    return axios
      .get(API_URL + 'licencies/' + id, { headers: authHeader() })
  }

  postLicencies (body) {
    return axios
      .post(API_URL + 'licencies', body, { headers: authHeader() })
  }

  deleteLicencies (id) {
    return axios
      .delete(API_URL + 'licencies/' + id, { headers: authHeader() })
  }
}

export default new CoursesService()
