<script setup lang="ts">
import { ref } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

</script>

<template>
    <div class="text_titre">
        <h1>Nos entraîneurs du club</h1>
    </div>
    <section class="containerEntraineurs">
        <div class="entraineur">
            <img src="@/assets/elodie.jpg" class="imgEntraineur" alt="">
            <h1>Elodie</h1>
            <h3>Référente Eveil Athlé, Poussins et Benjamins</h3>
            <p>Lancers</p>
        </div>
        <div class="entraineur">
            <img src="@/assets/christophe.jpg" class="imgEntraineur" alt="">
            <h1>Christophe</h1>
            <h3>Entraîneur</h3>
            <p>Marche nordique</p>
        </div>
        <div class="entraineur">
            <img src="@/assets/sghaier.jpg" class="imgEntraineur" alt="">
            <h1>Sghaier</h1>
            <h3>Référent Minimes</h3>
            <p>Sauts</p>
        </div>
        <div class="entraineur">
            <img src="@/assets/benoit.jpg" class="imgEntraineur" alt="">
            <h1>Benoit</h1>
            <h3>Référent Sprint</h3>
            <p>Piste</p>
        </div>
        <div class="entraineur">
            <img src="@/assets/marcel.jpg" class="imgEntraineur" alt="">
            <h1>Marcel</h1>
            <h3>Référent Demi-Fond</h3>
            <p>Piste</p>
        </div>
        <div class="entraineur">
            <img src="@/assets/olivier.jpeg" class="imgEntraineur" alt="">
            <h1>Olivier</h1>
            <h3>Référent Running</h3>
            <p>Hors stade</p>
        </div>
        <div class="entraineur">
            <img src="@/assets/eric.jpg" class="imgEntraineur" alt="">
            <h1>Eric</h1>
            <h3>Référent Marche Nordique</h3>
            <p>Marche nordique</p>
        </div>
        <div class="entraineur">
            <img src="@/assets/margareth.jpg" class="imgEntraineur" alt="">
            <h1>Margareth</h1>
            <h3>Entraîneur</h3>
            <p>Marche nordique</p>
        </div>
    </section>
</template>

<style scoped>
.text_titre {
    width: 30%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 5%;
    text-align: center;
    border: 5px solid #fdce08;
}
.containerEntraineurs{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin: auto;
}
.entraineur{
    width: 22%;
    text-align: center;
    margin-bottom: 4%
}
.entraineur h1{
    color: #0872b1;
    font-size: 220%;
    margin-top: 2%;
    margin-bottom: 0;
}
.entraineur h3{
    margin-top: 2%;
    margin-bottom: 0;
}
.entraineur p{
    margin-top: 2%;
    font-size: 120%;
    color: #979797;
    font-weight: bold
}
.imgEntraineur{
    width: 80%;
    border-radius: 150px;
}
@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
  .imgEntraineur{
    width: 60%;
  }
  .entraineur{
    width: 100%;
  }
}
</style>
