<script setup lang="ts">
import moment from 'moment'
import 'moment/dist/locale/fr'
import { ref } from 'vue'
moment.locale('fr')

// eslint-disable-next-line no-undef
const data = defineProps({
  date: Number
})

var year = moment().year()

if (new Date() < new Date(moment().month(9).startOf('M').format('DD/MM/YYYY'))) {
  year = moment().add(1, 'years').year()
}

</script>
<template>
    <span class="itemCateg eveil" v-if="6 <= year - moment(data.date).year() && 9 >= year - moment(data.date).year()">Eveil Athlé</span>
    <span class="itemCateg poussins" v-if="10 <= year - moment(data.date).year() && 11 >= year - moment(data.date).year()">Poussins</span>
    <span class="itemCateg benjamins" v-if="12 <= year - moment(data.date).year() && 13 >= year - moment(data.date).year()">Benjamins</span>
    <span class="itemCateg minimes" v-if="14 <= year - moment(data.date).year() && 15 >= year - moment(data.date).year()">Minimes</span>
    <span class="itemCateg cadets" v-if="16 <= year - moment(data.date).year() && 17 >= year - moment(data.date).year()">Cadets</span>
    <span class="itemCateg cadets" v-if="18 <= year - moment(data.date).year() && 19 >= year - moment(data.date).year()">Juniors</span>
    <span class="itemCateg fond" v-if="20 <= year - moment(data.date).year() && 22 >= year - moment(data.date).year()">Espoirs</span>
    <span class="itemCateg fond" v-if="23 <= year - moment(data.date).year() && 34 >= year - moment(data.date).year()">Seniors</span>
    <span class="itemCateg running" v-if="35 <= year - moment(data.date).year()">Masters</span>
</template>
<style>
    .itemCateg{
      padding: 6%;
      border-radius: 5px;
      text-align: center;
    }
</style>
