<script setup lang="ts">

import { ref } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import router from '@/router'
import store from '@/store'

const user = ref({
  user: '',
  password: ''
})
const password = ref('')
const errorMessage = ref(null)
const route = useRoute()

function handleSubmit (e: any) {
  store.dispatch('auth/login', user.value).then(
    () => {
      router.push('/admin')
    },
    (error: { response: { data: { message: any; }; }; message: any; toString: () => any; }) => {
      alert(error)
    }
  )
}
</script>
<template >
    <section class="container">
        <div class="login">
            <div class="containerLogoConnexion">
                <img class="logoConnexion" src="@/assets/logo ogsa.png" alt="">
            </div>
            <form @submit.prevent="handleSubmit($event)" class="form">
                <div class="containerInput">
                    <h3>Utilisateur</h3>
                    <input type="text" v-model="user.user" />
                </div>
                <div class="containerInput">
                    <h3>Mot de passe</h3>
                    <input type="password" name="password" v-model="user.password" />
                </div>
                <button class="buttonLogin">Connexion</button>
            </form>
        </div>
    </section>
</template>
<style>
    .container{
        width: 100%;
        height: 100vh;
        background-image: url('@/assets/pexels-lukas-hartmann-1127120.jpg');
        background-size: cover;
    }
    .container h2{
        margin-top: -6%;
        text-align: center;
        color: #0872b1;
    }
    .login{
        position: absolute;
        top: 6%;
        right: 5%;
        background-color: #ffffff;
        width: 30%;
        border-radius: 10px;
        height: 80vh;
        padding: 2%;
        animation-name: animate-pop;
        animation-timing-function: cubic-bezier(0.26,0.53,0.74,1.48);
        animation-duration: 1s;
    }
    @keyframes animate-pop{
        0% {
            opacity: 0;
            transform: scale(0.5,0.5);
        }
        100% {
            opacity: 1;
            transform: scale(1,1);
        }
    }
    .containerLogoConnexion{
        display: flex;
        width: 100px;
        height: 100px;
        border-radius: 100px;
        padding: 4%;
        background-color: #ebebeb;
        margin: auto;
        margin-top: 5%;
        margin-bottom: 5%
    }
    .logoConnexion{
        width: 100%;
        object-fit: contain;
    }
    .form{
        display: flex;
        flex-direction: column;
    }
    .containerInput{
        width: 80%;
        align-self: center;
    }
    .containerInput h3{
        margin-bottom: 0;
        font-weight: 500;
    }
    .containerInput input{
        width: 90%;
        border-radius: 5px;
        font-size: 150%;
        padding: 4%;
        border: none;
        background-color: #ebebeb;
        margin-bottom: 5%;
    }
    .buttonLogin{
        margin-top: 10%;
        width: 80%;
        color: #fff;
        padding: 4%;
        border-radius: 5px;
        font-size: 175%;
        border: none;
        background-color: #0872b1;
        align-self: center;
    }
</style>
