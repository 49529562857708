<script setup lang="ts">

import { ref } from 'vue'
import ArticlesService from '../services/articles.service.js'
import CardArticles from '@/components/CardArticles.vue'

const articles = ref(null)
ArticlesService.getAllArticles().then((response: { data: null; }) => {
  articles.value = response.data
})
</script>
<template>
    <div class="text_titre">
      <h1>Les actualités</h1>
    </div>
    <section v-if="articles">
        <masonry-wall :items="articles" :ssr-columns="1" :column-width="350" :gap="16" style="width: 85%; margin: auto; margin-top: 5%;">
            <template #default="{ item }">
                <CardArticles
                :title="item.Titre"
                :description1="item.Description1"
                :description2="item.Description2"
                :description3="item.Description3"
                :image="item.Img"
                :publie="item.PubliePar"
                :categorie="item.Categories"
                :date="item.Date"
                />
            </template>
        </masonry-wall>
    </section>
  </template>

  <style scoped>
  .text_titre {
    width: 30%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 5%;
    text-align: center;
    border: 5px solid #fdce08;
  }
  @media screen and (max-width : 1024px) {
    .text_titre {
      margin-top: 5%;
      width: 80%;
      font-size: 80%;
    }
  }

  </style>
